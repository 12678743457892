<template>
  <v-app>
    <v-container>
      <v-btn to="/dashboard" class="mb-6"> Home </v-btn>
      <div v-for="permission in permissions" :key="permission" class="mb-3">
        {{ permission }}
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Permissions',
  computed: {
    permissions() {
      return this.$store.state.$account?.permissions || []
    }
  }
}
</script>
